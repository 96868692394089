import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import IconArrowGreenRight from '../../images/icon-arrow-green-right.png';

export const MainDiv = styled.div`
  text-align: center;
`;

export const custompadding = styled(Col)`
  padding: 0px 50px 0px 0px;
  @media (max-width: 1199px) { 
    padding: 0px 40px 0px 0px;
  }
  @media (max-width: 767px) {
    padding: 0px 0px 0px 0px; 
  }
`

export const BlockQuote = styled.blockquote`
  width: 100%;
  margin: 0 0 50px 0;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  border-radius: 20px; 
  text-align: center;
  /* :last-child {
    margin-bottom: 0;
  } */
  /* :nth-last-child(odd) {
    background: red;
    color: red;
    margin-bottom: 0;
  } */
  @media (max-width: 1399px) {
    padding: 60px !important; 
    margin: 0 0 50px 0;
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 1199px) { 
    margin: 0 0 60px 0;
    padding: 40px !important;
  }
  @media (max-width: 991px) {
    padding: 40px !important; 
    margin: 0 0 60px 0;
  }
  @media (max-width: 767px) {
    padding: 30px !important;
    border-radius: 10px;
    margin: 0 0 30px 0;
  }
`;
export const Code = styled.p`
  margin-bottom: 40px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  display: inline;
  font-style: italic;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 1399px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;

export const ParaText = styled.p`
  font-family: 'Figtree',arial,sans-serif;
  @media (max-width: 1399px) {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  @media (max-width: 991px) { 
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 30px;
  }
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 17px 35px 13px 35px;
    margin: 0 5px 5px 0;
    font-family: 'Adelle-Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #55aa4c;
    border: 2px solid #55aa4c;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    /* code {
      font: inherit;
    } */
    /* @media (max-width: 1399px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    @media (max-width: 1199px) {
      font-size: 25px;
      line-height: 25px;
    } */

    @media (max-width: 767px) {
      padding: 10px 20px 6px 20px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
    }
    :hover {
      top: -5px;
      color: #55aa4c;
      border-color: #55aa4c;
      box-shadow: 0 5px 0 0 #55aa4c;
    }
  }

  i {
    font-style: italic;
    margin-bottom: 0 !important;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 24px;
    }
    @media (max-width: 1199px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 991px) { 
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

export const ImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  width: 100%;
  height: auto;
  margin: 0 0 40px 0;
`;
export const CardImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  height: 100px;
  margin: 0 0 40px 0;
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #55aa4c;
  transition: 0.25s ease-out;
  cursor: pointer;
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const ListItemArrow = styled.li`
  padding: 0 0 15px 30px;
  background: url(${IconArrowGreenRight}) no-repeat 0 9px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  text-align: left;
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
export const HeaderH2 = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
  text-align: ${(props) => (props.$textAlign ? 'center' : 'left')};
  @media (max-width: 1399px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const SubText = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
  }
`;

export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin: 0 5px 5px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #55aa4c;
  border: 2px solid #55aa4c;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  /* @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 25px;
  } */

  @media (max-width: 767px) {
    padding: 10px 20px 6px 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
  }
  :hover {
    top: -5px;
    color: #55aa4c;
    border-color: #55aa4c;
    box-shadow: 0 5px 0 0 #55aa4c;
  }
`;
